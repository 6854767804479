// src/App.js
import React, { useState } from 'react';
import Persona from './Persona';
import './App.css';

function App() {
  const [personaData, setPersonaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPersona = async () => {
    setLoading(true);
    setError(null);

    try {
      // Make a request to the Cloudflare Worker endpoint
      const response = await fetch('/masqueraid-worker');
      if (!response.ok) {
        throw new Error('Failed to fetch persona');
      }

      const data = await response.json();
      setPersonaData(data);  // Store the fetched persona data in state
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <h1>Masqueraid Persona Generator</h1>
      <button onClick={fetchPersona} disabled={loading}>
        {loading ? 'Generating...' : 'Generate Persona'}
      </button>

      {error && <p className="error">Error: {error}</p>}

      {/* Render Persona component only if personaData is available */}
      {personaData && <Persona personaData={personaData} />}
    </div>
  );
}

export default App;
