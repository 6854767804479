// src/Persona.js
import React from 'react';

function Persona({ personaData }) {
  const { persona, imageUrl } = personaData;  // Destructure the persona details and image URL

  return (
    <div className="persona">
      <h2>Generated Persona</h2>
      <p><strong>Details:</strong> {persona}</p>
      
      {/* Display the generated image */}
      {imageUrl && <img src={imageUrl} alt="Generated Persona" />}
    </div>
  );
}

export default Persona;
